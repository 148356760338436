import { ref } from 'vue';
import { useStore } from 'vuex';

const useSelectCompany = () => {
  let selectCompany = ref('');
  const store = useStore();
  
  const setSelectCompany = (company) =>{
    company = company || localStorage.getItem('company');
    selectCompany.value = company;
    store.dispatch('user/changeCompany', {company: selectCompany.value});
  }
    
  return {
    setSelectCompany,
    selectCompany
  }
};

export default useSelectCompany;
